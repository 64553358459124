import React from "react"
import { Link } from "gatsby"

import resume from "../../content/assets/Luiz Quenji Kato Junior - Resume.pdf"

const activeStyles = {
  color: '#333'
};

const Nav = () => (
  <nav>
    <div className="container">
      <div className="grid">
        <div className="hide column-sm-6">
          <ul className="nav-name">
            <li><Link to="/">Luiz Quenji Kato Junior</Link></li>
          </ul>
        </div>
        <div className="column-xs-12 column-sm-6">
          <ul className="nav-links">
            <li><Link to="/" className="nav-item" activeStyle={activeStyles}>Home</Link></li>
            <li><Link to="/about" className="nav-item" activeStyle={activeStyles}>About</Link></li>
            <li><a href={resume} className="nav-item" rel="noopener noreferrer" target="_blank">Resume</a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
)

export default Nav
