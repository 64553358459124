import React from "react"
import { Link } from "gatsby"

import Nav from "./nav"
import "./layout.scss"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link>
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3>
          <Link>
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div role="button" tabIndex={0} onContextMenu={(e) => e.preventDefault()}>
        <Nav title={header} />
        {children}
      </div>
    )
  }
}

export default Layout
